<template>
  <div class="box">
    <div class="lists">
      <div class="listBox">
        <div class="list">
          <div class="listRight">
            <div>{{ list.ShopName }}
              <span class="icon"
                v-if="list.Status == 1"
                style="background: #6493e3;color: #fff;">
                {{ list.StatusDesc }}</span>
              <span class="icon"
                v-if="list.Status == 2"
                style="background: #f9e75f;color: #000;">
                {{ list.StatusDesc }}</span>
              <span class="icon"
                v-if="list.Status == 3"
                style="background: #ff9632;color: #fff;">
                {{ list.StatusDesc }}</span>
              <span class="icon"
                v-if="list.Status == 4"
                style="background: #47e39c;color: #fff;">
                {{ list.StatusDesc }}</span>
              <span class="icon"
                v-if="list.Status == 9"
                style="background: #e35258;color: #fff;">
                {{ list.StatusDesc }}</span>
            </div>
            <div class="titleC">
              <img src="../../assets/shop/shopd.png"
                v-if="list.OrderMan">
              <span style="font-size:16px;color:#F09B0B"
                v-if="list.OrderMan">下单人:{{ list.OrderMan
                            }}</span>
              <img src="../../assets/shop/shopb.png"
                v-if="list.OrderTime">
              <span v-if="list.OrderTime">下单时间:{{ list.OrderTime }}</span>
            </div>
            <div class="titleC"
              v-if="list.RecManAddr">
              <img src="../../assets/shop/shope.png"
                v-if="list.RecManAddr">
              <span v-if="list.RecManAddr">下单地址:{{ list.RecManAddr }}</span>
            </div>

          </div>
        </div>
      </div>
      <div class="listBox">
        <div class="list">
          <div class="listRight">
            <div class="title">商品信息</div>
            <!-- <van-swipe :loop="false" :width="130" show-indicators="false"> -->
            <!-- <van-swipe-item> -->
            <div class="goodsList"
              style="padding-bottom: 15px;">
              <img :src="list.GoodsImgUrl">
              <div style="font-size: 14px;"><span style="margin: 0px 10px 5px;">数量:{{ list.GoodsNum }}</span>
                <span style="color:#FF4A26"><span style="font-size: 12px;">积分</span><span style="padding-left: 3px;">{{ list.Amount }}</span>
                </span>
              </div>
            </div>
            <!-- </van-swipe-item> -->
            <!-- </van-swipe> -->
          </div>
        </div>
      </div>
      <div class="listBox"
        v-if="list.Status >= 4 && list.Status != 9">
        <div class="list">
          <div class="listRight">
            <div class="title">接单信息</div>
            <div style="font-size:14px;margin: 0px 14px 5px;">
              <span>接单人:{{ list.RecMan }}</span>
              <span style="margin-left:8px">接单时间:{{ list.RecTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="listBox"
        v-if="list.Status >= 5 && list.Status != 9">
        <div class="list">
          <div class="listRight">
            <div class="title">配送信息</div>
            <div style="font-size:14px;margin: 0px 14px 5px;">
              <span>配送人:{{ list.DelMan }}</span>
              <span style="margin-left:8px">配送时间:{{ list.DelTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="listBox"
        v-if="list.Status >= 6 && list.Status != 9">
        <div class="list">
          <div class="listRight">
            <div class="title">结单信息</div>
            <div style="font-size:14px;margin: 0px 14px 5px;">
              <span>结单人:{{ list.FinishMan }}</span>
              <span style="margin-left:8px">结单时间:{{ list.FinishTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="listBox"
        v-if="list.EvaStatus == 2">
        <div class="list">
          <div class="listRight">
            <div class="title">评价信息</div>
            <div style="margin: 0px 14px;font-size: 14px;color: #333;">{{ list.EvalContent }}</div>
            <div>
              <span style="font-size:14px;margin:0px 7px;color:#ccc"></span>
              <van-rate v-model="list.Satisfaction"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                :size="18" />
            </div>
            <img :src="list.EvalImgsUrl"
              style="padding: 10px 19px;"
              v-if="list.EvalImgs">
          </div>
        </div>
      </div>
      <div class="listBox"
        v-if="list.Status == 9">
        <div class="list">
          <div class="listRight">
            <div class="title">撤单信息</div>
            <div class="titleC"
              v-if="list.RevReason">
              <img src="../../assets/shop/shopc.png">
              <span>撤单原因:{{ list.RevReason }}</span>
            </div>
            <div class="titleC">
              <img src="../../assets/shop/shopb.png"
                v-if="list.RevTime">
              <span style="font-size:12px;color:#F09B0B">撤单时间:{{ list.RevTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getOpenId } from "@/utils/auth";
import { WeGetMyOrder } from '@/api/shop';
export default {
  data () {
    return {
      dataForm: {
        // openID:getOpenId(),
        openID: "oebDw5XNiVzsIUaP__HJXNUd6krs",
      },
      list: [],
    }
  },
  mounted () {
    this.orderId = this.$route.query.Id;
    this.getDetail(this.orderId)
  },
  methods: {
    getDetail (id) {
      this.dataForm.orderId = id;
      WeGetMyOrder(this.dataForm).then((res) => {
        this.list = res.data.data;
        this.list.OrderTime = this.dataFormat(this.list.OrderTime);
        this.list.RecTime = this.dataFormat(this.list.RecTime);
        this.list.EvalTime = this.dataFormat(this.list.EvalTime);

      })
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  }
}
</script>
<style scoped>
.box {
  background: #f5f5f5;
  min-height: 100vh;
}

.lists {
  display: inline-block;
  float: left;
  width: 95%;
  margin: 0px 2.5%;
  overflow-y: scroll;
  padding-bottom: 60px;
}

.lists .listBox {
  position: relative;
  margin: 10px 0px;
  background: #fff;
  border-radius: 8px;
}

.lists .list {
  display: flex;
  position: relative;
}

.lists .list img {
  width: 85px;
  height: 85px;
  border-radius: 25px;
  padding: 8px;
}

.lists .list .listRight {
  width: 100%;
}

.list .listRight div:nth-child(1) {
  float: left;
  font-size: 14px;
  font-weight: 700;
  margin: 5px 5px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  position: relative;
  padding-bottom: 5px;
}

.list .listRight div:nth-child(2) {
  /* margin: 0px 5px;
    position: absolute;
    bottom: 3px;
    width: 55%; */
}

.list .listRight .addcar {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #ffca36;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  position: absolute;
  right: 0px;
}

.shopbox {
  width: 95%;
  margin: 10px 2.5%;
  padding-bottom: 15px;
}

.shopbox .goodsList img {
  width: 95px;
  height: 70px;
  border-radius: 8px;
}

.shopbox .goodsList div:nth-child(3) {
  color: #ff4a26;
  margin-top: 3px;
}

.shopbox .goodsList div:nth-child(2) {
  font-size: 12px;
  width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/deep/.shopbox .van-swipe__indicators {
  display: none;
}

.list .listRight .titleC {
  float: left;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 3px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
}

.list .listRight .titleC span {
}

.list .listRight .titleC img {
  width: 15px;
  height: 15px;
  padding: 0px 5px;
  margin: 0px;
}

.list .Amount {
  position: absolute;
  right: 5px;
  color: #e36b6b;
}

.list .icon {
  position: absolute;
  right: 5px;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 8px;
}

.lists .list .listRight .title {
  padding-left: 10px;
}

.listBox .title::before {
  content: '';
  position: absolute;
  left: 0px;
  width: 4px;
  height: 20px;
  background: #f9e75f;
  border-radius: 5px;
}
</style>